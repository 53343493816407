/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { SelectOption } from '@/ui/types';
import cn from '@/utils/cn';
const Select = dynamic(() => import('react-select'));
type TabNavPrimaryProps<T> = {
  pills?: boolean;
  currentPage: T;
  pages: T[];
  onClickTab: (page: T) => void;
  addBottomBorder?: boolean;
};
export default function TabNavPrimary<T>({
  pills,
  pages,
  currentPage,
  onClickTab,
  addBottomBorder
}: TabNavPrimaryProps<T>) {
  return <div className={classNames(addBottomBorder && !pills ? 'mt-3 sm:border-b md:border-b-0 lg:border-b' : '')} data-sentry-component="TabNavPrimary" data-sentry-source-file="TabNavPrimary.tsx">
      <div className="mt-2 sm:hidden md:flex lg:hidden">
        <div className="sr-only">Select a tab</div>

        <Select options={pages.map(page => ({
        value: page?.toString(),
        label: page?.toString()
      }))} defaultValue={currentPage?.toString()} value={currentPage?.toString()} onChange={newVal => {
        onClickTab((newVal as SelectOption).value);
      }} className="mt-2 w-full text-sm capitalize" placeholder={currentPage?.toString()} classNamePrefix="select" data-sentry-element="Select" data-sentry-source-file="TabNavPrimary.tsx" />
      </div>
      <div className={cn(pills ? 'gap-x-1' : 'gap-x-4', 'tabs mt-1 hidden flex-wrap items-center sm:flex md:hidden lg:flex')}>
        {pages.map(page => {
        return <button key={page?.toString()} type="button" className={classNames(currentPage === page ? pills ? `border-blue-100 bg-blue-50 text-primary-default` : `border-primary-default text-primary-default` : pills ? 'border-transparent text-gray-500 hover:text-gray-700' : `border-transparent text-gray-500 hover:border-gray-500`, pills ? 'cursor-pointer whitespace-nowrap rounded-md border px-4 py-2 font-medium capitalize' : 'cursor-pointer whitespace-nowrap border-b-2 py-3 font-medium capitalize', pages.length > 1 ? 'text-sm' : 'text-base leading-tight')} onClick={() => {
          onClickTab(page);
        }}>
              {page?.toString()}
            </button>;
      })}
      </div>
    </div>;
}