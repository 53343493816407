import { Bars3Icon } from '@heroicons/react/24/outline';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logo from '@/public/assets/logos/tech1m.sidebar.logo.svg';
import cn from '@/utils/cn';
type MobileMenuHeaderProps = {
  onClickMenuBars: () => void;
};
export default function MobileMenuHeader({
  onClickMenuBars
}: MobileMenuHeaderProps) {
  return <div className="sticky top-0 z-40 flex bg-white py-1 pl-1 sm:pl-3 sm:pt-3 md:hidden" data-sentry-component="MobileMenuHeader" data-sentry-source-file="MobileMenuHeader.tsx">
      <button type="button" className={cn('-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center', 'justify-center rounded-md text-gray-500 hover:text-gray-900', 'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500')} onClick={onClickMenuBars}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" data-sentry-element="Bars3Icon" data-sentry-source-file="MobileMenuHeader.tsx" />
      </button>

      <Link href="/dashboard" className="flex flex-shrink-0 items-center px-4" data-sentry-element="Link" data-sentry-source-file="MobileMenuHeader.tsx">
        <Image src={logo} alt="Tech1M Logo" data-sentry-element="Image" data-sentry-source-file="MobileMenuHeader.tsx" />
      </Link>
    </div>;
}