/* eslint-disable max-len */

import cn from '@/utils/cn';
type Props = {
  overlay?: boolean;
  className?: string;
};
const LoaderSvg = ({
  className
}: {
  className?: string;
}) => {
  return <svg className={cn('h-14 w-14 animate-spin text-blue-600', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="LoaderSvg" data-sentry-source-file="LoaderSpinner.tsx">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="LoaderSpinner.tsx" />
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" data-sentry-element="path" data-sentry-source-file="LoaderSpinner.tsx" />
      {/* <path
          className="opacity-75"
          fill="currentColor"
          d="M2 12a8 2 0 018-8V0C2.686 0 0 2.686 0 6h2zm1 5.291A7.962 7.962 0 012 12H0c0 3.042 1.135 5.824 3 7.938l1.5-1.324z"
        /> */}
    </svg>;
};
export default function LoaderSpinner({
  overlay = false,
  className
}: Props) {
  return overlay ? <div className={cn('absolute inset-0 z-20 flex h-full w-full items-center justify-center bg-white bg-opacity-60', className)}>
      <LoaderSvg className={className} />
    </div> : <div className={className}>
      <LoaderSvg className={className} />
    </div>;
}