/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-dom-props */
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React from 'react';
import userImage from '@/public/assets/images/user-image.png';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
type ComponentType = typeof Image;
type AvatarPrimaryProps = Parameters<ComponentType>[0] & {
  isOnline?: boolean;
  isVerified?: boolean;
  size?: number;
  hideIndicators?: boolean;
  alt?: string;
  sizes?: [n: number, sm?: number, md?: number, lg?: number];
  resize?: boolean;
  square?: boolean;
};
const AvatarPrimary = ({
  src,
  size,
  isOnline,
  hideIndicators,
  alt,
  square,
  isVerified,
  ...otherProps
}: AvatarPrimaryProps) => {
  const avatarSize = size || 40;
  const indicatorOffset = avatarSize > 40 ? avatarSize / 20 : -2;
  const indicatorSize = avatarSize > 40 ? 16 : 12;
  return <div className="dark-image-mode relative flex h-min w-min justify-center" data-sentry-component="AvatarPrimary" data-sentry-source-file="AvatarPrimary.tsx">
      <div className={classNames(square ? '' : 'rounded-full', 'flex-shrink-0 overflow-clip bg-gray-200', otherProps.className)} style={{
      height: avatarSize,
      width: avatarSize
    }}>
        <Image alt={alt || ''} layout="responsive" width={avatarSize} height={avatarSize} {...otherProps} src={src || userImage} data-sentry-element="Image" data-sentry-source-file="AvatarPrimary.tsx" />
      </div>
      {Boolean(!hideIndicators && isOnline) && <span style={{
      bottom: indicatorOffset,
      right: indicatorOffset,
      height: indicatorSize,
      width: indicatorSize
    }} className={classNames('absolute rounded-full border-2 border-white', isOnline ? 'bg-green-600' : 'bg-gray-500')} />}

      {Boolean(!hideIndicators && isVerified) && <span style={{
      bottom: indicatorOffset,
      right: indicatorOffset,
      height: indicatorSize,
      width: indicatorSize
    }} className="absolute">
          <CheckBadgeIcon className="h-4 w-4 text-teal-600" />
        </span>}
    </div>;
};
export default AvatarPrimary;