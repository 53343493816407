import classNames from 'classnames';
import { TabNavWithCountPage } from './types';
import { TagLabel } from '../Tag';
import dynamic from 'next/dynamic';
import { SelectOption } from '@/ui/types';
import cn from '@/utils/cn';
const Select = dynamic(() => import('react-select'));
type TabNavWithCountProps = {
  currentPage: string;
  pages: TabNavWithCountPage[];
  onClickTab: (pageTitle: string) => void;
  extraTabs?: {
    tabs: TabNavWithCountPage[];
    node: React.ReactNode;
  };
};
export default function TabNavWithCount({
  pages,
  currentPage,
  onClickTab,
  extraTabs
}: TabNavWithCountProps) {
  const allPages = [...pages, ...(extraTabs?.tabs || [])];
  return <div className={classNames(extraTabs ? 'sm:border-b md:border-0 lg:border-b' : 'sm:border-b', 'flex flex-wrap justify-between gap-y-2 lg:gap-y-0')} data-sentry-component="TabNavWithCount" data-sentry-source-file="TabNavWithCount.tsx">
      <div className={cn('w-full lg:w-auto', extraTabs ? 'mr-2' : '', 'mt-3')}>
        <div className={cn('w-full', allPages.length > 5 ? 'flex lg:hidden' : 'sm:hidden md:flex lg:hidden')}>
          <div className="sr-only">Select a tab</div>

          <Select options={allPages.map(page => ({
          value: page.title,
          label: `${page.title} ${page?.count?.toString() ? ` [${page.count}]` : ''}`
        }))} defaultValue={currentPage?.toString()} value={currentPage?.toString()} onChange={newVal => {
          onClickTab((newVal as SelectOption).value);
        }} className="mt-2 w-full text-sm capitalize" placeholder={currentPage?.toString()} classNamePrefix="select" data-sentry-element="Select" data-sentry-source-file="TabNavWithCount.tsx" />
        </div>
        <div className={classNames(allPages.length > 5 ? 'hidden lg:flex' : 'hidden sm:flex md:hidden lg:flex', 'tabs -mb-px mt-1 h-[95%] flex-wrap items-center gap-x-4 sm:h-auto')} aria-label="Tabs">
          {pages.map(page => {
          const isActive = currentPage === page.title;
          return <button key={page.title} type="button" className={classNames(isActive ? `border-primary-default text-primary-default` : `border-transparent text-gray-500 hover:border-gray-500`, 'h-full cursor-pointer whitespace-nowrap border-b-2 py-3 font-medium capitalize', pages.length > 2 ? 'text-sm' : 'text-base')} onClick={() => {
            onClickTab(page.title);
          }}>
                {page.title}
                <span className="ml-3 hidden xl:inline-flex">
                  <TagLabel text={page?.count.toString() || ''} inActive={!isActive} />
                </span>
              </button>;
        })}
        </div>
      </div>
      {extraTabs && <div className={cn(allPages.length > 5 ? 'hidden lg:flex' : 'hidden sm:flex md:hidden lg:flex')}>
          {extraTabs?.node || null}
        </div>}
    </div>;
}