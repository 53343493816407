import { PlayIcon } from '@heroicons/react/20/solid';
import dynamic from 'next/dynamic';
import React from 'react';
import { ReactPlayerProps } from 'react-player';
// import PlayIcon from '@/public/assets/icons/play-icon.svg';

const ReactPlayer = dynamic(() => import('react-player'), {
  ssr: false
});
type VideoPrimaryProps = ReactPlayerProps;
export default function VideoPrimary({
  url,
  ...otherProps
}: VideoPrimaryProps) {
  return <div className="h-full w-full" data-sentry-component="VideoPrimary" data-sentry-source-file="VideoPrimary.tsx">
      <ReactPlayer playIcon={<PlayIcon className="h-16 w-16 text-white" />} url={url || ''} {...otherProps} data-sentry-element="ReactPlayer" data-sentry-source-file="VideoPrimary.tsx" />
    </div>;
}