/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
type BasicAlertProps = {
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertText?: string;
  onDismiss: () => void;
};
export default function BasicAlert({
  alertType,
  alertText,
  onDismiss: handleDismiss
}: BasicAlertProps) {
  if (alertType === 'error') {
    return <div className="rounded-md bg-red-50 p-2">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">{alertText || 'Error Occurred!'}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button onClick={handleDismiss} type="button" className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>;
  }
  if (alertType === 'warning') {
    return <div className="rounded-md bg-orange-50 p-2">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-orange-800">{alertText || 'Warning!'}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button onClick={handleDismiss} type="button" className="inline-flex rounded-md bg-orange-50 p-1.5 text-orange-500 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-orange-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>;
  }
  if (alertType === 'info') {
    return <div className="rounded-md bg-blue-50 p-2">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-blue-800">{alertText || 'Info!'}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button onClick={handleDismiss} type="button" className="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>;
  }
  return <div className="rounded-md bg-green-50 p-2" data-sentry-component="BasicAlert" data-sentry-source-file="BasicAlert.tsx">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" data-sentry-element="CheckCircleIcon" data-sentry-source-file="BasicAlert.tsx" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{alertText || 'Success'}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button onClick={handleDismiss} type="button" className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="BasicAlert.tsx" />
            </button>
          </div>
        </div>
      </div>
    </div>;
}