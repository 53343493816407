import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React from 'react';
import Link from 'next/link';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, DropdownMenuGroup } from '@/ui/dropdown-menu';
import { Button } from '@/ui/button';
type MenuItems = Array<{
  label: string;
  href: Array<string>;
  target?: string;
  rel?: string;
  divide?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
}>;
export default function SideAccountDrop({
  menuItems
}: {
  menuItems: MenuItems;
}) {
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="SideAccountDrop" data-sentry-source-file="SidebarAccountDropdown.tsx">
      <DropdownMenuTrigger data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="SidebarAccountDropdown.tsx">
        <div>
          <Button variant="ghost" className="flex h-6 items-center rounded-full bg-gray-100 p-1 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100" data-sentry-element="Button" data-sentry-source-file="SidebarAccountDropdown.tsx">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" data-sentry-element="EllipsisHorizontalIcon" data-sentry-source-file="SidebarAccountDropdown.tsx" />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent data-sentry-element="DropdownMenuContent" data-sentry-source-file="SidebarAccountDropdown.tsx">
        <DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="SidebarAccountDropdown.tsx">Options</DropdownMenuLabel>
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="SidebarAccountDropdown.tsx" />
        <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="SidebarAccountDropdown.tsx">
          {menuItems.map(linkProps => {
          const {
            label,
            href,
            divide,
            iconLeft,
            iconRight,
            rel,
            target
          } = linkProps || {};
          return <div key={href + label}>
                {divide ? <DropdownMenuSeparator /> : null}
                <DropdownMenuItem>
                  <Link shallow rel={rel} target={target} as={href[1] || undefined} href={href[0] || '/dashboard'} className="flex w-full justify-between">
                    {iconLeft || null}
                    <span>{label}</span>
                    {iconRight || null}
                  </Link>
                </DropdownMenuItem>
              </div>;
        })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>;
}