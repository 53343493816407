import cn from '@/utils/cn';
import React from 'react';
type TagLabelProps = {
  text?: string;
  color?: string;
  backgroundColor?: string;
  inActive?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  textClassName?: string;
};
export default function TagLabel({
  text,
  inActive,
  iconLeft,
  iconRight,
  children,
  className,
  textClassName
}: TagLabelProps) {
  return <div className={cn('inline-flex items-center rounded-full p-[1.5px] px-2', inActive ? 'bg-gray-100 text-gray-600' : 'bg-blue-50 text-primary-default', className)} data-sentry-component="TagLabel" data-sentry-source-file="TagLabel.tsx">
      {iconLeft}
      {children ? children : <span className={cn('text-xs font-medium', textClassName)}>{text}</span>}
      {iconRight}
    </div>;
}